<template>
  <button v-bind="$attrs">
    <slot></slot>
    <svg width="16" height="16" viewBox="0 0 16 16">
      <circle
        r="6"
        cx="8"
        cy="8"
        fill="transparent"
        stroke="#ffffff"
        stroke-width="1"
        stroke-dasharray="30 5"
      />
    </svg>
  </button>
</template>
<script>
export default {
  name: "SexyButton",
};
</script>
<style>
button {
  position: relative;
  appearance: none;
  cursor: pointer;
  font-family: "Be Vietnam Pro", sans-serif;
  background: rgb(var(--primary));
  border: none;
  border-radius: 8px;
  color: #fff;
  padding: 8px 14px;
  padding-right: 14px;
  transition: padding 0.1s, opacity 0.2s;
}

button:disabled{
  pointer-events: none;
  opacity:  0.3;
}

button + button{
  margin-left:  5px;
}

button svg {
  position: absolute;
  right: 8px;
  top: 8px;
  opacity: 0;
  animation: spin 1s infinite linear;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

button.is-loading {
  padding-right: 30px;
  pointer-events: none;
  opacity: 0.6;
}
button.is-loading svg {
  opacity: 1;
}

button:hover {
  box-shadow: 0 0 0 2px rgb(var(--primary) / 25%);
  background: rgb(var(--primary) / 90%);
}

.button-row {
  display: flex;
  margin: 0 -3px;
  flex-wrap: wrap;
  justify-content: center;
}

.button-row button {
  margin: 0 3px;
}

.is-secondary {
  background: transparent;
  box-shadow: inset 0 0 0 1px rgb(var(--primary) / 25%);
  color: var(--primary);
}
.is-secondary circle {
  stroke: rgb(var(--primary));
}
.is-secondary:hover {
  background: transparent;
  box-shadow: inset 0 0 0 1px rgb(var(--primary) / 50%),
    0 0 0 2px rgb(var(--primary) / 25%);
}

button.is-danger {
  background: rgb(var(--danger));
}
button.is-danger:hover {
  box-shadow: 0 0 0 2px rgb(var(--danger) / 25%);
  background: rgb(var(--danger) / 90%);
}
</style>
