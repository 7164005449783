<template>
  <div class="ticker" :class="{ 'is-ticking': ticking }">
    <svg class="ticker--svg" width="600" height="150" viewBox="0 0 600 150">
      <defs>
        <filter id="gooify" width="400%" x="-150%" height="400%" y="-150%">
          <feGaussianBlur
            id="blurElement"
            in="SourceGraphic"
            stdDeviation="20"
            result="blur"
          />
          <feColorMatrix
            in="blur"
            mode="matrix"
            values="1 0 0 0 0
                    0 1 0 0 0
                    0 0 1 0 0
                    0 0 0 255 -153"
          />
        </filter>
      </defs>
      <g filter="url(#gooify)">
        <circle class="ticker--trail-4" r="30" cx="70" cy="75" fill="#aaaaaa" />
        <circle class="ticker--trail-3" r="35" cx="70" cy="75" fill="#888888" />
        <circle class="ticker--trail-2" r="40" cx="70" cy="75" fill="#666666" />
        <circle class="ticker--trail-1" r="45" cx="70" cy="75" fill="#333333" />
        <circle class="ticker--circle" r="50" cx="70" cy="75" fill="#000000" />
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    ticking: Boolean,
  },
};
</script>

<style>
.ticker {
  animation: blink 3s infinite;
}
.ticker.is-ticking {
  animation: none;
}
.ticker--svg {
  width: 80px;
  height: auto;
}
.ticker--circle,
.ticker--trail-1,
.ticker--trail-2,
.ticker--trail-3,
.ticker--trail-4,
.ticker--trail-5 {
  animation: pulse 2s infinite;
  animation-play-state: paused;
}
.ticker--trail-1 {
  animation-delay: 0.07s;
}
.ticker--trail-2 {
  animation-delay: 0.12s;
}
.ticker--trail-3 {
  animation-delay: 0.18s;
}
.ticker--trail-4 {
  animation-delay: 0.24s;
}
.ticker--trail-5 {
  animation-delay: 0.3s;
}
.ticker.is-ticking .ticker--circle,
.ticker.is-ticking .ticker--trail-1,
.ticker.is-ticking .ticker--trail-2,
.ticker.is-ticking .ticker--trail-3,
.ticker.is-ticking .ticker--trail-4,
.ticker.is-ticking .ticker--trail-5 {
  animation-play-state: running;
}

@keyframes pulse {
  0% {
    animation-timing-function: ease-out;
    cx: 70px;
  }
  50% {
    animation-timing-function: ease-out;
    cx: 530px;
  }
  100% {
    animation-timing-function: ease-out;
    cx: 70px;
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
