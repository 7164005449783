import { db } from "./App.js";
import { collection, addDoc } from "firebase/firestore";
import dayjs from "dayjs";

export const createTimer = async (args) => {
  const commit = {
    uid: args.uid,
    users: [args.uid],
    started_at: Date.now(),
    store: 0,
    archived: false,
    ticking: true,
    label: dayjs().format("D MMMM h:mma"),
    created_at: Date.now(),
    updated_at: Date.now(),
    ...args,
  };
  try {
    const docRef = await addDoc(collection(db, "timers"), commit);
    return docRef;
  } catch (e) {
    console.error("Error adding document: ", e);
  }
};
