import { auth } from "./App.js";
import { signInAnonymously, onAuthStateChanged } from "firebase/auth";
import store from "@/store/index.js";

onAuthStateChanged(auth, (user) => {
  if (user) {
    // User is signed in, see docs for a list of available properties
    // https://firebase.google.com/docs/reference/js/firebase.User
    store.commit("setUID", user.uid);
  } else {
    console.log("signed out")
    store.commit("setUID", null);
  }
});


export const signIn = async () => {
  signInAnonymously(auth)
    .then(() => {
      console.log("signed in")
    })
    .catch((error) => {
      console.log(error);
      // ...
    });
};

export const signOut = async () => {
  auth.signOut();
}