import { createStore } from "vuex";
import { createTimer } from "@/firebase/Timers.js";
import router from "@/router/index.js";

export default createStore({
  state: {
    uid: null,
  },
  mutations: {
    setUID(state, uid) {
      state.uid = uid;
    },
  },
  actions: {
    async createTimer({ state }) {
      const { id } = await createTimer({ uid: state.uid });
      router.push(`/timers/${id}`);
    },
  },
  modules: {},
});
