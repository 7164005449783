import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAYHh9II1gJEi-K1-ax9aV7ivyEGDzVl3A",
  authDomain: "time-74ae3.firebaseapp.com",
  projectId: "time-74ae3",
  storageBucket: "time-74ae3.appspot.com",
  messagingSenderId: "760451973721",
  appId: "1:760451973721:web:31e8bf650bd1dc64966748"
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
export const auth = getAuth(firebaseApp);
export const db = getFirestore(firebaseApp);
