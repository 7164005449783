const MINUTE = 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;

const pad = (num) => {
  num = Math.max(num, 0) + "";
  return num.padStart(2, "0");
};

const timeFormat = (totalTimeSeconds) => {
  // const days = Math.floor(totalTimeSeconds / DAY);
  const hours = pad(Math.floor((totalTimeSeconds % DAY) / HOUR));
  const minutes = pad(Math.floor((totalTimeSeconds % HOUR) / MINUTE));
  const seconds = pad(Math.floor(totalTimeSeconds % MINUTE));
  if (hours > 0) {
    return `${hours}:${minutes}:${seconds}`;
  }
  return `${minutes}:${seconds}`;
};
export { timeFormat };
