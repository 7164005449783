<template>
  <div class="home">
    <div v-if="uid">
      <header v-if="!editing">
        <SexyButton @click="createTimer">Create timer</SexyButton>
        <SexyButton class="is-secondary" @click="editing = true">Edit...</SexyButton>
      </header>
      <header v-else>
        <SexyButton @click="edited = []; editing = false" :class="{'is-secondary': edited.length > 0}">Cancel</SexyButton>
        <div>
          <SexyButton :class="{'is-loading': archiving}" @click="archiveAll" :disabled="!edited.length">Archive</SexyButton>
          <SexyButton @click="deleteAll" :disabled="!edited.length" class="is-danger">Delete</SexyButton>
        </div>
      </header>
      <div
        class="timer-row"
        v-for="timer in timers"
        :key="'link' + timer.uid"
      >

        <input :id="'checkbox-'+timer.id" type="checkbox" v-model="edited" :value="timer.id" style="display: none" />
        <label v-if="editing" :for="'checkbox-'+timer.id" class="checkbox-label">
          <svg viewBox="0 0 24 24">
            <path fill="rgb(88, 86, 214)" d="M24 5.827L21.17 3 9.107 15.345 2.83 9.06 0 11.89 9.106 21 24 5.827z"/>
          </svg>
        </label>

        <router-link
          class="timer-link"
          :to="'/timers/' + timer.id"
        >
          <span>{{ timer.label }} &rarr;</span>
          <Ticker v-if="timer.ticking" :ticking="true" />
          <div v-else>
            <span v-if="timer.store > day"
              >{{ Math.floor(timer.store / day) }}d</span
            >
            {{ timeFormat(timer.store / 1000) }}
          </div>
        </router-link>
      </div>
    </div>
    <div v-else>
      <SexyButton @click="signIn">Sign in</SexyButton>
      <SexyButton @click="signOut">Sign out</SexyButton>
    </div>
  </div>
</template>

<script>
import { db } from "@/firebase/App.js";
import { signIn, signOut } from "@/firebase/User.js";
import SexyButton from "@/components/SexyButton.vue";
import Ticker from "@/components/Ticker.vue";
import { mapState } from "vuex";
import { getDocs, collection, query, where, orderBy, limit, updateDoc, doc } from "firebase/firestore";
import { timeFormat } from "../helpers.js";

export default {
  name: "Home",
  components: {
    SexyButton,
    Ticker,
  },
  computed: {
    ...mapState(["uid"]),
  },
  data() {
    return {
      editing: false,
      timers: [],
      edited: [],
      archiving: false,
      deleting: false,
      day: 1000 * 60 * 60 * 24,
    };
  },
  watch: {
    // call again the method if the route changes
    $route: "fetchTimers",
    uid: "fetchTimers",
  },
  methods: {
    async archiveAll(){
      this.archiving = true;
      await Promise.all(this.edited.map(id => {
        return updateDoc(doc(db, "timers", id), {
          archived: true
        }, {
          merge: true
        })
      }));
      await this.fetchTimers();
      this.archiving = false;
      this.edited = [];
      this.editing = false;
    },
    deleteAll(){
      console.log('cant do this yet');
    },
    timeFormat(n) {
      return timeFormat(n);
    },
    async fetchTimers() {
      this.timers = [];
      if (!this.uid) {
        return;
      }
      const timersRef = collection(db, "timers");
      const q = query(
        timersRef,
        where("archived", "==", false),
        where("users", "array-contains", this.uid),
        orderBy("created_at", "desc"),
        limit(30)
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((d) =>
        this.timers.push({
          id: d.id,
          ...d.data(),
        })
      );

      document.title =
        "Time — " +
        querySnapshot.docs.length +
        " timer" +
        (querySnapshot.docs.length == 1 ? "" : "s");
    },
    async signIn() {
      return await signIn();
    },
    async signOut() {
      return await signOut();
    },
    createTimer() {
      return this.$store.dispatch("createTimer");
    },
  },
  created() {
    this.fetchTimers();
  },
};
</script>

<style>
  .home {
    padding: 8px;
  }

  .checkbox-label,
  .timer-link{
    display: block;
    padding: 10px;
    display: flex;
    border-radius: 8px;
    box-shadow: inset 0 0 0 1px rgb(var(--primary) / 25%);
  }

  .checkbox-label{
    height: 100%;
    color:  transparent;
    width:  40px;
    cursor:  pointer;
  }

  .checkbox-label svg{
    opacity:  0;
  }

  input:checked + .checkbox-label svg{
    opacity:  1;
  }


  .timer-link {
    width: 100%;
    justify-content: space-between;
    text-decoration: none;
    background: transparent;
    color: var(--primary);
  }
  .timer-link:hover {
    background: rgb(var(--primary) / 5%);
  }

  .timer-row{
    margin-top: 10px;
    display:  flex;
    align-items:  center;
    gap: 10px;
  }

  header{
    display:  flex;
    justify-content: space-between;
  }
</style>
