<template>
  <metainfo>
    <template v-slot:title="{ content }">{{
      content ? `${content} | SITE_NAME` : `SITE_NAME`
    }}</template>
  </metainfo>
  <router-view />
</template>

<style>
:root {
  --primary: 88 86 214;
  --danger: 255 59 48;
}

* {
  box-sizing: border-box;
}

html,
body,
#app {
  font-family: "Be Vietnam Pro", sans-serif;
  height: 100%;
  margin: 0;
  padding: 0;
}

#nav {
  position: absolute;
}

input:not([type="checkbox"]):not([type="radio"]),
select {
  appearance: none;
  border: none;
  padding: 4px;
  border-radius: 4px;
  font-family: "Be Vietnam Pro", sans-serif;
}
input:not([type="checkbox"]):not([type="radio"]):not(.invisible-input) {
  box-shadow: inset 0 0 0 1px rgb(var(--primary) / 25%);
}

select {
  margin: 0 4px;
  padding-left: 15px;
  padding-right: 15px;
  display: inline-block;
}
input:not([type="checkbox"]):not([type="radio"]):focus {
  outline: none;
  box-shadow: 0 0 0 2px rgb(var(--primary) / 25%);
}
</style>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState(["uid"]),
  },
};
</script>
